.circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  /* background-color: rgb(255, 255, 255); */
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 99999999;
}
