
.swiper {
  width: 5.4rem;
  margin-left: 0;
  margin-right: 0; 
  font-size: 2rem;
}

@media only screen and (min-width: 768px) {
  .swiper {
    width: 15rem;
    margin-left: 0;
    margin-right: 0;
    font-size: 5.5rem;
  }
}