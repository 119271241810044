@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
* {
  margin: 0;
  padding: 0;
  font-family: "Merriweather", serif;;
}
/* ::-webkit-scrollbar {
  display: none;
} */
